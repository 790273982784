import "bootstrap";
import "slick-carousel";
import { countries } from "./countries";

(function ($) {
  // On Dom Ready
  $(function () {
    // Submit Pin Form
    $(document).on("submit", "#pinForm", function (e) {
      e.preventDefault();
      var pinForm = $("#pinForm");
      var pinSubmitButton = $("#pinSubmitButton");

      $.ajax({
        type: "POST",
        url: pinForm.attr("action"),
        data: pinForm.serialize(),
        beforeSend: function () {
          pinSubmitButton.attr("disabled", "true");
          $(".global-message").html("");
          $(".invalid-feedback").html("");
          pinForm.find(".is-invalid").removeClass("is-invalid");
        },
        success: function (response) {
          //console.log(response);
          pinForm.trigger("reset");
          $(".global-message").html('<div class="alert alert-success">Please wait...</div>');

          // Load Main Form inside Form Container
          $("#formContainer").load($("#base_url").val() + "template-parts/_main_form.php", function () {
            handle_main_form();
          });
        },
        error: function (request, status, error) {
          if (request.status == 500) {
            $.each(request.responseJSON, function (index, value) {
              pinForm.find("#" + index).addClass("is-invalid");
              pinForm.find('[data-error-element="' + index + '"]').html(value);
            });
          } else {
            $(".global-message").html('<div class="alert alert-danger">' + request.responseText + "</div>");
          }
        },
        complete: function () {
          pinSubmitButton.removeAttr("disabled");
        },
      });
    });

    function handle_main_form() {
      fillCountries();

      // Submit Pin Form
      $(document).on("submit", "#mainForm", function (e) {
        e.preventDefault();
        var mainForm = $("#mainForm");
        var mainSubmitButton = $("#mainSubmitButton");

        $.ajax({
          type: "POST",
          url: mainForm.attr("action"),
          data: mainForm.serialize(),
          beforeSend: function () {
            mainSubmitButton.attr("disabled", "true");
            $(".global-message").html("");
            $(".invalid-feedback").html("");
            mainForm.find(".is-invalid").removeClass("is-invalid");
          },
          success: function (response) {
            mainForm.trigger("reset");
            mainForm.hide();
            $(".global-message").html('<div class="alert alert-success">Data submitted successfully</div>');
          },
          error: function (request, status, error) {
            if (request.status == 500) {
              $.each(request.responseJSON, function (index, value) {
                mainForm.find("#" + index).addClass("is-invalid");
                mainForm.find('[data-error-element="' + index + '"]').html(value);
              });
            } else {
              $(".global-message").html('<div class="alert alert-danger">' + request.responseText + "</div>");
            }
          },
          complete: function () {
            mainSubmitButton.removeAttr("disabled");
          },
        });
      });
    }

    function fillCountries() {
      var options = "";
      for (var i = 0; i < countries.length; i++) {
        options += '<option value="' + countries[i] + '">' + countries[i] + "</option>";
      }
      $("#country").append(options);
    }
  });
})(jQuery);
